import { Text } from 'ui/baseComponents'

const DailyChartTitle = ({ children, ...props }) => (
  <Text {...props} sx={{ zIndex: 1 }}>
    <span
      style={{
        backgroundColor: 'white',
        display: 'inline-block',
        textTransform: 'capitalize',
      }}
    >
      {children}
    </span>
  </Text>
)

export default DailyChartTitle
