import { Collapse } from '@mui/material'
import { useState } from 'react'
import { colors } from 'theme/colors'
import { Button, Stack, Text } from 'ui/baseComponents'
import DailyCategoryFilter from 'ui/clinicianScreens/Patient/Daily/components/DailyCategoryFilter'
import { SECTION_TITLES } from 'ui/clinicianScreens/Patient/Daily/consts'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import useScrollTrigger from '@mui/material/useScrollTrigger'

const DailyFilters = ({
  hasTremorData,
  hasDyskinesiaData,
  hasMedicationEvents,
  hasSymptomEvents,
  hasNotesEvents,
  hasCheckinEvents,
  isTremorFilterActive,
  setIsTremorFilterActive,
  isDyskinesiaFilterActive,
  setIsDyskinesiaFilterActive,
  isMedicationFilterActive,
  setIsMedicationFilterActive,
  medicationInitialFilters,
  medicationActiveFilters,
  setMedicationActiveFilters,
  isSymptomFilterActive,
  setIsSymptomFilterActive,
  symptomInitialFilters,
  symptomActiveFilters,
  setSymptomActiveFilters,
  isNotesFilterActive,
  setIsNotesFilterActive,
  // isSleepFilterActive,
  // setIsSleepFilterActive,
  isCheckinFilterActive,
  setIsCheckinFilterActive,
}) => {
  const [showFilters, setShowFilters] = useState(true)
  const toggleFilters = () => setShowFilters(!showFilters)
  const trigger = useScrollTrigger({ threshold: 860 })

  return (
    <Stack
      sx={{
        backgroundColor: colors.COOL[100],
      }}
    >
      <Stack
        sx={{
          position: 'sticky',
          top: trigger ? 0 : '73px',
          transition: 'all 0.3s ease',
        }}
      >
        <Stack sx={{ padding: '1rem' }}>
          <Button onClick={toggleFilters} type="secondary" size="large">
            {showFilters ? (
              <KeyboardDoubleArrowLeftIcon />
            ) : (
              <KeyboardDoubleArrowRightIcon />
            )}
          </Button>
        </Stack>
        <Collapse in={showFilters} orientation="horizontal">
          <Text variant="head20B" component="h2" sx={{ paddingLeft: '1.8rem' }}>
            Filters
          </Text>
          <Stack spacing={0} sx={{ width: '25rem' }}>
            <DailyCategoryFilter
              {...{
                title: SECTION_TITLES.tremor,
                isCategoryActive: isTremorFilterActive,
                onToggleCategory: () =>
                  setIsTremorFilterActive(!isTremorFilterActive),
                disableFilter: !hasTremorData,
              }}
            />
            <DailyCategoryFilter
              {...{
                title: SECTION_TITLES.dyskinesia,
                isCategoryActive: isDyskinesiaFilterActive,
                onToggleCategory: () =>
                  setIsDyskinesiaFilterActive(!isDyskinesiaFilterActive),
                disableFilter: !hasDyskinesiaData,
              }}
            />
            <DailyCategoryFilter
              {...{
                title: SECTION_TITLES.medication,
                isCategoryActive: isMedicationFilterActive,
                onToggleCategory: () =>
                  setIsMedicationFilterActive(!isMedicationFilterActive),
                initialFilters: medicationInitialFilters,
                activeFilters: medicationActiveFilters,
                onChange: (e) => setMedicationActiveFilters(e.target.value),
                disableFilter: !hasMedicationEvents,
              }}
            />
            <DailyCategoryFilter
              {...{
                title: SECTION_TITLES.symptoms,
                isCategoryActive: isSymptomFilterActive,
                onToggleCategory: () =>
                  setIsSymptomFilterActive(!isSymptomFilterActive),
                initialFilters: symptomInitialFilters,
                activeFilters: symptomActiveFilters,
                onChange: (e) => setSymptomActiveFilters(e.target.value),
                disableFilter: !hasSymptomEvents,
              }}
            />
            {/* <DailyCategoryFilter
            {...{
              title: SECTION_TITLES.sleep,
              isCategoryActive: isSleepFilterActive,
              onToggleCategory: () =>
                setIsSleepFilterActive(!isSleepFilterActive),
            }}
          /> */}
            <DailyCategoryFilter
              {...{
                title: SECTION_TITLES.checkin,
                isCategoryActive: isCheckinFilterActive,
                onToggleCategory: () =>
                  setIsCheckinFilterActive(!isCheckinFilterActive),
                disableFilter: !hasNotesEvents,
              }}
            />
            <DailyCategoryFilter
              {...{
                title: SECTION_TITLES.notes,
                isCategoryActive: isNotesFilterActive,
                onToggleCategory: () =>
                  setIsNotesFilterActive(!isNotesFilterActive),
                disableFilter: !hasCheckinEvents,
              }}
            />
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  )
}

export default DailyFilters
