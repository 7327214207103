import { BarChart, Stack } from 'ui/baseComponents'
import { formatDailyEventsBarChartData } from 'ui/clinicianScreens/Patient/Daily/sections/helpers'
import { colors } from 'theme/colors'
import DailyChartTitle from 'ui/clinicianScreens/Patient/Daily/components/DailyChartTitle'
import {
  SECTION_TITLES,
  smallChartHeightWithAxis,
  smallChartHeightWithoutAxis,
} from 'ui/clinicianScreens/Patient/Daily/consts'

const DailySymptoms = ({ date, data = [], activeFilters = [] }) => {
  const recentPeriodLegendItem = {
    name: SECTION_TITLES.symptoms,
    color: colors.PRIMARY[1000],
  }

  const filteredData = formatDailyEventsBarChartData({
    date,
    data,
    activeFilters,
    label: SECTION_TITLES.symptoms,
  })

  return filteredData.length > 0 ? (
    <Stack>
      <DailyChartTitle variant="head18B" component="h2">
        {SECTION_TITLES.symptoms}
      </DailyChartTitle>
      {filteredData.map((item, i, a) => {
        const isLastItem = a.length - 1 === i

        return (
          <Stack spacing={0} key={i}>
            <DailyChartTitle
              key={item.timestamp}
              variant="body14"
              component="h3"
            >
              {item[0].title}
            </DailyChartTitle>
            <Stack sx={{ paddingLeft: '60px' }}>
              <BarChart
                {...{
                  title: SECTION_TITLES.symptoms,
                  height: isLastItem
                    ? smallChartHeightWithAxis
                    : smallChartHeightWithoutAxis,
                  barSize: null,
                  barCategoryGap: 0,
                  barGap: 0,
                  data: item,
                  legendItem: recentPeriodLegendItem,
                  yAxisMax: 1,
                  yAxisUnit: ' mins',
                  legend: false,
                  isAnimationActive: false,
                  hideYAxis: true,
                  hideXAxis: !isLastItem,
                  hideXAxisTickLine: true,
                  hideVerticalGridLines: true,
                  hideTopXAxisGridLines: true,
                  chartId: `daily-chart-symptoms-${date}`,
                }}
              />
            </Stack>
          </Stack>
        )
      })}
    </Stack>
  ) : (
    <div />
  )
}

export default DailySymptoms
