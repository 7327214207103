import { Stack, Text } from 'ui/baseComponents'
import { getDataByDate } from 'ui/clinicianScreens/Patient/Daily/sections/helpers'
import DailyChartTitle from 'ui/clinicianScreens/Patient/Daily/components/DailyChartTitle'
import {
  convertUnixTimestampToSpecifiedTimezone,
  SECOND_IN_MILLISECONDS,
} from 'utilities/time'
import { memo, useContext } from 'react'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import { SECTION_TITLES } from 'ui/clinicianScreens/Patient/Daily/consts'

const DailyNotes = ({ date, data = [] }) => {
  const { selectedTimezone } = useContext(DateRangeContext)

  const dataByDate = getDataByDate(data, date)

  const formattedNoteData = dataByDate.map((event) => {
    const time = convertUnixTimestampToSpecifiedTimezone({
      timestamp: event.duration.startTime * SECOND_IN_MILLISECONDS,
      timezoneName: selectedTimezone,
      format: 'hh:mm A',
    })

    const content = Object.entries(JSON.parse(event.payload)).map(
      (item) => item[1],
    )

    return {
      time,
      content,
    }
  })

  return dataByDate.length > 0 ? (
    <Stack>
      <DailyChartTitle variant="head18B" component="h2">
        {SECTION_TITLES.notes}
      </DailyChartTitle>
      {formattedNoteData.map((note, i) => (
        <Text key={i} variant="body16" component="p">
          <Text variant="body16B">{note.time}: </Text>
          {note.content}
        </Text>
      ))}
    </Stack>
  ) : (
    <div />
  )
}

export default memo(DailyNotes)
