import moment from 'moment'
import { getEventName } from 'ui/clinicianScreens/Patient/helpers'
import { roundToNearestTenMinutes } from 'ui/clinicianScreens/Patient/Overview/helpers'
import { getChartIntervals } from 'ui/clinicianScreens/Patient/Report/helpers'

export const getDataByDate = (data, date) =>
  data.filter((event) => {
    const { startTime } = event?.duration || {}
    const momentStartTime = moment.unix(startTime)
    return momentStartTime.isSame(date, 'day')
  })

const formatDailyEventsByBarChartIntervals = ({ item, data = [], label }) => {
  const chartIntervals = getChartIntervals()

  const formattedData = chartIntervals.map((timestamp) => ({
    timestamp,
    name: moment(timestamp, 'hh:mm A').format('h A'),
    title: item,
    [label]: 0,
  }))

  data.forEach((event, i) => {
    const index = formattedData.findIndex(
      (item) =>
        item.timestamp === moment(event.startTimeInterval).format('hh:mm A'),
    )

    for (let j = 0; j < event.durationIntervals; j++) {
      if (formattedData[index + j]) {
        formattedData[index + j][label] = 1
      }
    }
  })

  return formattedData
}

export const formatDailyEventsBarChartData = ({
  data,
  date,
  activeFilters,
  label,
}) => {
  const dataByDate = getDataByDate(data, date)

  const formattedEventData = dataByDate.map((event) => {
    // get number of 10 minute intervals between startTimeInterval and endTimeInterval
    const { startTime, endTime } = event?.duration || {}
    const momentStartTime = moment.unix(startTime)
    const momentEndTime = moment.unix(endTime)
    const startTimeInterval = roundToNearestTenMinutes(momentStartTime)
    const endTimeInterval = roundToNearestTenMinutes(momentEndTime)
    const duration = moment.duration(endTimeInterval.diff(startTimeInterval))
    const durationIntervals = duration.asMinutes() / 10 || 1

    return {
      title: getCombinedMedicationNameAndDosage(event),
      name: moment(startTimeInterval).format('h A'),
      timestamp: moment(startTimeInterval).format('hh:mm A'),
      startTimeInterval,
      durationIntervals,
    }
  })

  const dataByEventName = formattedEventData.reduce((acc, item) => {
    if (!acc[item.title]) {
      acc[item.title] = []
    }
    acc[item.title].push(item)
    return acc
  }, {})

  const formattedChartData = Object.entries(dataByEventName).map(
    ([key, value]) =>
      formatDailyEventsByBarChartIntervals({
        item: key,
        data: value,
        label,
      }),
  )

  const filteredData = formattedChartData.filter((item) =>
    activeFilters.includes(item[0].title),
  )

  const alphabeticallySortedData = filteredData.sort((a, b) =>
    a[0].title.localeCompare(b[0].title),
  )

  return alphabeticallySortedData
}

export const getCombinedMedicationNameAndDosage = (event) => {
  const name = getEventName(event)
  const { payload } = event
  const eventPayload = JSON.parse(payload)
  const dosage = eventPayload?.dosage
  const dosageStrengthQuantity = eventPayload?.dosage_strength?.quantity
  const dosageStrengthUnit = eventPayload?.dosage_strength?.unit
  let doseText = name

  if (dosage && dosageStrengthQuantity) {
    doseText += ` ${dosage} x ${dosageStrengthQuantity}${dosageStrengthUnit}`
  } else if (dosageStrengthQuantity) {
    doseText += ` ${dosageStrengthQuantity}${dosageStrengthUnit}`
  } else if (dosage) {
    doseText += ` ${dosage} pills`
  }

  return doseText
}
