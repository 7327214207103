/**
 * Helper functions for datetime parsing and formatting.
 */
import moment from 'moment-timezone'
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates'
import { duration } from 'ui/clinicianScreens/Patient/consts'
import { MAX_RANGE_ADJUSTED } from 'ui/components/LoadMoreMonths'
import { DATE_FORMAT } from 'ui/consts'

/**
 * A second in nanoseconds
 */
export const SECOND_IN_NANOSECONDS = 1000000000

/**
 * A second in milliseconds
 */
export const MILLISECONDS_IN_SECONDS = 0.001

/**
 * A second in milliseconds
 */
export const SECOND_IN_MILLISECONDS = 1000

/**
 * A second in microseconds
 */
export const SECOND_IN_MICROSECONDS = 1000000

/**
 * A minute, in seconds.
 */
export const MINUTE_IN_SECONDS = 60

/**
 * Fifteen minutes, in seconds.
 */
export const FIFTEEN_MINUTES_IN_SECONDS = 900

/**
 * Ten minutes, in seconds.
 */
export const TEN_MINUTES_IN_SECONDS = 600

/**
 * An hour, in minutes.
 */
export const HOUR_IN_MINUTES = 60

/**
 * An hour, in seconds.
 */
export const HOUR_IN_SECONDS = HOUR_IN_MINUTES * MINUTE_IN_SECONDS

/**
 * A single day, in hours.
 */
export const DAY_IN_HOURS = 24

/**
 * A single day, in minutes.
 */
export const DAY_IN_MINUTES = DAY_IN_HOURS * HOUR_IN_MINUTES

/**
 * A single day, in seconds.
 */
export const DAY_IN_SECONDS = DAY_IN_HOURS * HOUR_IN_SECONDS

/**
 * A single week, in days.
 */
export const WEEK_IN_DAYS = 7

/**
 * A single week, in seconds.
 */
export const WEEK_IN_SECONDS = 7 * 24 * 60 * 60

/**
 * Two weeks, in days.
 */
export const TWO_WEEKS_IN_DAYS = 14

/**
 * Midday hour representation.
 */
export const NOON_HOUR = 12

/**
 * Additional day to add to a date range
 */
export const ONE_DAY = 1

/**
 * Interval constant used when splitting up a day into minutes (default being 10 min chunks)
 */

export const DEFAULT_TIME_RANGE_INTERVAL = 10

/**
 * Default date range, set to 2 weeks minus a day (so as to include the end date or start date as the 14th day)
 */

export const DEFAULT_DATE_RANGE_DAYS = 13
/**
 * Default date range, set to 2 weeks minus a day (so as to include the end date or start date as the 14th day)
 */
export const DEFAULT_DATE_RANGE_MONTHS = 2

/**
 * Const used in the padStart function to ensure hours have a 0 in front of them if they are single digit integers
 */
export const HOURS_STRING_PAD = 2

/**
 * Converts seconds to minutes
 * @param {number} seconds number of seconds
 * @returns {number} minutes
 */
export const secondsToMinutes = (seconds) => seconds / MINUTE_IN_SECONDS

/**
 *
 * @param {number} seconds number of seconds
 * @returns {string} formatted string of minutes and seconds
 */
export const secondsToRoundedMinutesAndSeconds = (seconds) =>
  moment.utc(1000 * seconds).format('mm[m] ss[s]')

/**
 * Returns the UNIX timestamp of an ISO date string without a time of day (e.g. '2021-01-01') in the browser's timezone.
 *
 * @param {string} strDate naive date string
 * @returns {number} UNIX timestamp
 */
export const dateToTimestamp = (strDate) =>
  moment(strDate).valueOf() / SECOND_IN_MILLISECONDS

/**
 * Return the timestamp coming from a local date string
 *
 * @param {string} strDate The date string in local time
 * @param {bool} startDate Boolean of start or end date (if end, we need the end of the day as well)
 * @returns {number} timestamp from a local date string
 */
export function localDateToUtcTimestamp(strDate, startDate = true) {
  return startDate
    ? moment.utc(strDate).valueOf() / SECOND_IN_MILLISECONDS
    : moment.utc(strDate).endOf('day').valueOf() / SECOND_IN_MILLISECONDS
}

/**
 * Format a MomentJS object for use with Plotly.js applying a local time
 * offset from UTC.
 *
 * @param {string} fmt format string
 * @param {moment.MomentInput} tMoment moment time value
 * @returns {string} a Plotly.js-ready MomentJS object
 */
export function fmtMomentLocal(fmt, tMoment) {
  const tzOffset =
    new Date().getTimezoneOffset() * MINUTE_IN_SECONDS * SECOND_IN_MILLISECONDS
  return moment.utc(tMoment.valueOf() - tzOffset).format(fmt)
}

/**
 * Gets the number of milliseconds since the Unix Epoch from a MomentJS object and formats it as a string.
 *
 * @param {string} format the desired output format
 * @param {moment.MomentInput} tMoment moment time value
 * @returns {string} a string representation of the time
 */
export function formatMomentToString(format, tMoment) {
  return moment(tMoment.valueOf()).format(format)
}

/**
 * Parse a naive datetime string into properly localized MomentJS object.
 *
 * @param {string} strDatetime naive datetime string
 * @returns {moment.Moment} moment time object
 */
export function parseNaiveDatetime(strDatetime) {
  let dateTimeString = strDatetime
  if (!dateTimeString.includes(':')) {
    // It's only a date: specify time-of-day explicitly
    dateTimeString += ' 00:00:00'
  }

  return moment(dateTimeString)
}

/**
 * Checks if the secondsSinceEpoch evaluates to today and returns true if so
 * @param {string} secondsSinceEpoch as stated.
 * @returns {boolean} if secondsSinceEpoch evaluates to today
 */
export function isToday(secondsSinceEpoch) {
  return moment(secondsSinceEpoch * SECOND_IN_MILLISECONDS).isSame(
    moment(),
    'day',
  )
}

/**
 * Checks if the secondsSinceEpoch evaluates to a date less than one hour
 * prior and returns true if so
 * @param {string} secondsSinceEpoch as stated.
 * @returns {boolean} if secondsSinceEpoch evaluates to a date less than one hour
 */
export function isSameHour(secondsSinceEpoch) {
  const oneHourAgo = moment().subtract(1, 'hour')
  return moment(secondsSinceEpoch * SECOND_IN_MILLISECONDS).isAfter(oneHourAgo)
}

/**
 * Checks if the secondsSinceEpoch evaluates to a date earlier than
 * N number of days prior to today.
 *
 * @param {string} secondsSinceEpoch as stated.
 * @param {moment.unitOfTime.DurationConstructor} nDaysAgo as stated.
 * @param {string | number} timeUnit arbitrary unit of time, as stated.
 *
 * @return {boolean}  if the secondsSinceEpoch evaluates to a date earlier than N number of days prior to today
 */
export function isOlderThanNDaysAgo(
  secondsSinceEpoch,
  nDaysAgo,
  timeUnit = 'days',
) {
  const cutoffDate = moment().subtract(nDaysAgo, timeUnit)
  return moment(secondsSinceEpoch * SECOND_IN_MILLISECONDS).isBefore(cutoffDate)
}

/**
 * Checks if the secondsSinceEpoch evaluates to a date earlier OR equal to
 * N number of days prior to today.
 *
 * @param {string} secondsSinceEpoch as stated.
 * @param {moment.unitOfTime.DurationConstructor} nDaysAgo as stated.
 * @param {string | number} timeUnit arbitrary unit of time, as stated.
 *
 * @return {boolean}  if the secondsSinceEpoch evaluates to a date earlier than N number of days prior to today
 */
export const isSameOrNewerThanNDaysAgo = (
  secondsSinceEpoch,
  nDaysAgo,
  timeUnit = 'days',
) => {
  const cutoffDate = moment().subtract(nDaysAgo, timeUnit)
  return moment(secondsSinceEpoch * SECOND_IN_MILLISECONDS).isSameOrAfter(
    cutoffDate,
  )
}

/**
 * Format time since epoch in seconds and display as a datetime
 *
 * @param {string} secondsSinceEpoch as stated.
 * @param {string} format format string
 *
 * @return {string} a datetime string
 */
export const formatDateTime = (
  secondsSinceEpoch,
  format = 'MMM DD, Y h:mm a',
) => moment(secondsSinceEpoch * SECOND_IN_MILLISECONDS).format(format)

/**
 * Format relative time since epoch in seconds
 *
 * @param {string} secondsSinceEpoch as stated.
 *
 * @return {string} Formatted relative time since epoch in seconds
 */
export const formatRelativeTime = (secondsSinceEpoch) =>
  moment(secondsSinceEpoch * SECOND_IN_MILLISECONDS).fromNow()

/**
 * Rounds down time portion of date to nearest interval time. Defaults to nearest 5 minutes.
 *
 * @param {string | number | Date} dateTime ISO timestamp
 * @param {number} interval Rounding interval, in minutes
 *
 * @returns {string} Hour:minute:secord portion of timestamp, rounded down
 */
export const roundDownIntervalTime = (
  dateTime,
  interval = duration.INTERVAL,
) => {
  const coeff = SECOND_IN_MILLISECONDS * MINUTE_IN_SECONDS * interval
  const date = new Date(dateTime)
  return new Date(
    Math.floor(date.getTime() / coeff) * coeff,
  ).toLocaleTimeString([], { hourCycle: 'h23' })
}

/**
 * Rounds up or down date to nearest interval time.
 *
 * @param {string | number | Date} dateTime ISO timestamp
 * @param {number} interval Rounding interval, in minutes
 *
 * @returns {string} timestamp, rounded up or down
 */
export const roundIntervalTime = (dateTime, interval = duration.INTERVAL) => {
  const coeff = SECOND_IN_MILLISECONDS * MINUTE_IN_SECONDS * interval
  const date = new Date(Math.round(new Date(dateTime) / coeff) * coeff)

  return date
}

/**
 * Check for validation of a date range with fallbacks if anything fails/doesn't exist/isn't valid
 *
 * @param {moment.MomentInput} dateStart start date
 * @param {moment.MomentInput} dateEnd end date
 * @param {boolean} isBrowseView is interval being rounded in browse view
 * @param {boolean} monthlyView is interval being rounded in monthly view
 * @param {boolean} isWeeklyView is interval being rounded in weekly view
 *
 * @returns {(*|string)[]|string|string[]|*[]|(string|string)[]} array of valid start / end dates
 */
export function validateDates(
  dateStart,
  dateEnd,
  isBrowseView = false,
  monthlyView = false,
  isWeeklyView = false,
) {
  let validatedStart = dateStart
  let validatedEnd = dateEnd

  // If end time is greater than start time in Browse view:
  if (isBrowseView) {
    validatedEnd = moment(dateStart)
      .add(MILLISECONDS_IN_SECONDS, 'seconds')
      .valueOf()
    return [moment(dateStart).valueOf(), validatedEnd]
  }

  if (isWeeklyView) {
    validatedStart = moment().subtract(8, 'weeks').format(DATE_FORMAT)
    validatedEnd = moment().format(DATE_FORMAT)
    return [validatedStart, validatedEnd]
  }

  // condition for arriving on the monthly view with no date params -- sets to 3 month range.
  if (monthlyView && !dateStart && !dateEnd) {
    validatedStart = moment()
      .subtract(DEFAULT_DATE_RANGE_MONTHS, 'months')
      .startOf('month')
      .format(DATE_FORMAT)
    validatedEnd = moment().endOf('month').format(DATE_FORMAT)
    return [validatedStart, validatedEnd]
  }

  // If anything is wrong with the start date but the end date is ok, keep end date and set start two weeks earlier
  if (
    (!dateStart || !moment(dateStart).isValid()) &&
    dateEnd &&
    moment(dateEnd).isValid()
  ) {
    validatedStart = moment(dateEnd)
      .subtract(DEFAULT_DATE_RANGE_DAYS, 'days')
      .format(DATE_FORMAT)
    return [validatedStart, dateEnd]
  }

  // If anything is wrong with both start and end date, default to the past two weeks
  if (
    (!dateStart || !moment(dateStart).isValid()) &&
    (!dateEnd || !moment(dateEnd).isValid())
  ) {
    validatedStart = moment()
      .subtract(DEFAULT_DATE_RANGE_DAYS, 'days')
      .format(DATE_FORMAT)
    validatedEnd = moment().format(DATE_FORMAT)
    return [validatedStart, validatedEnd]
  }

  // Prevents future start dates
  if (moment(dateStart) > moment()) {
    if (monthlyView) {
      validatedStart = moment()
        .subtract(DEFAULT_DATE_RANGE_MONTHS, 'months')
        .startOf('month')
      validatedEnd = moment().endOf('month')
    } else {
      validatedStart = moment().subtract(DEFAULT_DATE_RANGE_DAYS, 'days')
      validatedEnd = moment()
    }
    return [
      validatedStart.format(DATE_FORMAT),
      validatedEnd.format(DATE_FORMAT),
    ]
  }

  // If anything is wrong with the end date but the start date is ok, keep start date and set end a week later up to today at latest
  if (
    (!dateEnd || !moment(dateEnd).isValid()) &&
    dateStart &&
    moment(dateStart).isValid()
  ) {
    validatedEnd =
      moment(dateStart).add(DEFAULT_DATE_RANGE_DAYS, 'days') > moment()
        ? moment().format(DATE_FORMAT)
        : moment(dateStart)
            .add(DEFAULT_DATE_RANGE_DAYS, 'days')
            .format(DATE_FORMAT)
    return [dateStart, validatedEnd]
  }

  // If the start date is later than the end date, keep start date and set end a week later up to today at latest
  if (dateStart > dateEnd) {
    validatedEnd =
      moment(dateStart).add(DEFAULT_DATE_RANGE_DAYS, 'days') > moment()
        ? moment().format(DATE_FORMAT)
        : moment(dateStart)
            .add(DEFAULT_DATE_RANGE_DAYS, 'days')
            .format(DATE_FORMAT)
    return [dateStart, validatedEnd]
  }

  // If the end date is more than 2 weeks away from start date on daily view, keep start date and adjust end date to 2 weeks after but not exceeding today

  if (
    !monthlyView &&
    moment(dateStart).add(DEFAULT_DATE_RANGE_DAYS, 'days') < moment(dateEnd)
  ) {
    validatedStart = moment(dateEnd)
      .subtract(DEFAULT_DATE_RANGE_DAYS, 'days')
      .format(DATE_FORMAT)
    return [validatedStart, dateEnd]
  }

  // prevent future end dates in monthlyView
  if (monthlyView && moment(dateEnd) > moment().endOf('month')) {
    validatedEnd = moment().endOf('month').format(DATE_FORMAT)
    return [dateStart, validatedEnd]
  }

  // set 1 year max limit on monthlyView
  if (
    monthlyView &&
    moment(dateStart).add(MAX_RANGE_ADJUSTED, 'months') < moment(dateEnd)
  ) {
    validatedEnd = moment(dateStart)
      .add(MAX_RANGE_ADJUSTED, 'months')
      .format(DATE_FORMAT)
    return [dateStart, validatedEnd]
  }

  // For clinician portal queries the timestamp can't be exactly the same so we need to give time differences
  if (!monthlyView && dateStart === dateEnd) {
    validatedEnd = moment(dateEnd).endOf('day').format('YYYY-MM-DD HH:MM')
    validatedStart = moment(dateStart).startOf('day').format('YYYY-MM-DD HH:MM')
    return [validatedStart, validatedEnd]
  }

  // make sure monthly view always shows full months in the URL
  if (monthlyView) {
    validatedEnd = moment(dateEnd).endOf('month').format(DATE_FORMAT)
    validatedStart = moment(dateStart).startOf('month').format(DATE_FORMAT)
    return [validatedStart, validatedEnd]
  }

  return [validatedStart, validatedEnd]
}

export const SUBTRACT = 'subtract'
export const ADD = 'add'

/**
 * Given a date range and an operation, return a new date range after the operation has been completed.
 * Note that a date range cannot extend into the future; the maximum end date is today.
 * @param {object} params Object containing date range and operation params
 * @param {string} params.operation The operation to perform on the date range, e.g. 'add' or 'subtract'
 * @param {string} params.from The first date in the date range
 * @param {string} params.to The last date in the date range
 * @param {string} params.minimumDate The minimum (inclusive) allowed date
 * @param {string} params.maximumDate The maximum (inclusive) allowed date
 * @param {string} params.timezoneName The timezone name, e.g. 'America/Los_Angeles'
 * @returns {object} The new date range
 */
export const getDateRange = ({
  operation = SUBTRACT,
  from: currentFrom,
  to: currentTo,
  minimumDate,
  maximumDate,
  timezoneName,
}) => {
  const today = moment.tz(timezoneName).startOf('day')
  const days =
    moment
      .duration(
        moment
          .tz(currentTo, timezoneName)
          .diff(moment.tz(currentFrom, timezoneName)),
      )
      .asDays() + ONE_DAY
  const method = operation === SUBTRACT ? 'subtract' : 'add'
  let from = moment
    .tz(currentFrom, timezoneName)
    [method](days, 'day')
    .format(DATE_FORMAT)
  let to = moment
    .tz(currentTo, timezoneName)
    [method](days, 'day')
    .format(DATE_FORMAT)

  // Don't allow future dates
  if (moment.tz(to, timezoneName).isAfter(today)) {
    to = today.format(DATE_FORMAT)
  }

  const NEXT_OR_PREVIOUS_DAY = 1

  // Don't allow 'from' date that is less than the minimum date
  if (
    minimumDate &&
    isInclusivelyBeforeDay(
      moment.tz(from, timezoneName),
      moment.tz(minimumDate, timezoneName),
    )
  ) {
    from = moment
      .tz(minimumDate, timezoneName)
      .add(NEXT_OR_PREVIOUS_DAY, 'day')
      .format(DATE_FORMAT)
  }

  // Don't allow 'to' date that is more than the maximum date
  if (
    maximumDate &&
    isInclusivelyAfterDay(
      moment.tz(to, timezoneName),
      moment.tz(maximumDate, timezoneName),
    )
  ) {
    to = moment
      .tz(maximumDate, timezoneName)
      .subtract(NEXT_OR_PREVIOUS_DAY, 'day')
      .format(DATE_FORMAT)
  }

  return {
    from,
    to,
  }
}

/**
 * Formats a time (not datetime) object into a 12 hour readable format.
 *
 * @param {string} militaryTime The time to be formatted
 * @returns {string} A formatted 12-hour time string
 */
export const display12HourTime = (militaryTime) =>
  moment(militaryTime, 'HH:mm').format('h:mmA')

/**
 * Returns the timezone offset for the user's browser.
 *
 * @returns {number} Timezone offset, e.g. -25200
 */
export const getCurrentBrowserTimezoneOffset = () => {
  const today = new Date()

  return -today.getTimezoneOffset() * MINUTE_IN_SECONDS
}

/**
 * Rounds given time to hours and minutes and returns object representation.
 * @param {number} timeInHours duration of time, measured in hours
 * @returns {object} Object representation of time, e.g. { hours: 1, minutes: 30 }
 */
export const prettyPrintRoundedTimeInHoursAndMinutes = (timeInHours) => {
  const timeInMinutes = timeInHours * HOUR_IN_MINUTES
  const hours =
    timeInMinutes > 0
      ? Math.floor(timeInMinutes / HOUR_IN_MINUTES)
      : Math.ceil(timeInMinutes / HOUR_IN_MINUTES)
  const minutes = Math.round(timeInMinutes % HOUR_IN_MINUTES)

  if ((!hours ?? hours === 0) && (!minutes ?? minutes === 0)) {
    return { hours: 0, minutes: 0 }
  }

  if (!hours ?? hours === 0) {
    return { hours: 0, minutes }
  }

  if (!minutes ?? minutes === 0) {
    return { hours, minutes: 0 }
  }

  return { hours, minutes }
}

/**
 * Rounds given time to the nearest tenth of an hour and returns string representation.
 * If duration is 0, do not round to the nearest tenth.
 * @param {number} timeInHours duration of time, measured in hours
 * @param {string} units String representation of time units, defaults to 'hrs'
 * @returns {string} String representation of rounded time in hours with label
 */
export const prettyPrintRoundedTimeInHours = (timeInHours, units = 'hrs') => {
  units = units ? ` ${units}` : units

  const defaultText = `0${units}`
  const TENTHS_PLACE = 1

  if (!timeInHours) {
    return defaultText
  }

  const roundedTime = timeInHours.toFixed(TENTHS_PLACE)

  if (roundedTime === '0.0') {
    return defaultText
  }

  return `${roundedTime}${units}`
}

export const roundedTimeInDays = (timeInHours) => {
  const ZERO = 0
  const ONE = 1
  const timeInDays = timeInHours / DAY_IN_HOURS
  const roundedValue = timeInDays.toFixed(ONE)
  return roundedValue === '0.0' ? ZERO : roundedValue
}

export const getCurrentBrowserTimezoneName = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone

/**
 * Returns a date string that is two weeks before the initial input
 * @param {string | number | Date} dateTime ISO timestamp
 * @param {string} timezoneName as string (IANA timezone name) e.g. 'America/Los_Angeles'
 * @returns {string} A formatted date string
 */
export const getTwoWeeksBeforeTimestamp = (dateTime, timezoneName) =>
  moment
    .unix(dateTime)
    .tz(timezoneName)
    .subtract(TWO_WEEKS_IN_DAYS - 1, 'days')
    .format(DATE_FORMAT)

/**
 * Returns a date string from the given timestamp
 * @param {string | number | Date} dateTime ISO timestamp
 * @param {string} timezoneName as string (IANA timezone name) e.g. 'America/Los_Angeles'
 * @returns {string} A formatted date string
 */
export const getDateFromTimestamp = (dateTime, timezoneName) =>
  moment.unix(dateTime).tz(timezoneName).format(DATE_FORMAT)

/**
 * Returns a timestamp corresponding to the start of day for the given date string
 * @param {string} date as string (YYYY-MM-DD) or in ISO format
 * @param {string} timezoneName as string (IANA timezone name) e.g. 'America/Los_Angeles'
 * @returns {number} The timestamp of the start of the day.
 * If timezoneName is provided, the timestamp will be start of day for the provided timezoneName.
 * Otherwise, it will be start of day for the browser's timezone
 * @example
 * getTimestampStartOfDay('2023-01-01') // 1672531200
 * getTimestampStartOfDay('2023-01-01T00:00:00.000Z') // 1672531200
 */
export const getTimestampStartOfDay = (date, timezoneName) =>
  timezoneName
    ? moment.tz(date, timezoneName).startOf('day').unix()
    : moment(date).startOf('day').unix()

/**
 * Returns a timestamp corresponding to the start of day of the day following the given date string
 * @param {string} date as string (YYYY-MM-DD) or in ISO format
 * @param {string} timezoneName as string (IANA timezone name) e.g. 'America/Los_Angeles'
 * @returns {number} The timestamp of the start of the day
 * @example
 * getTimestampStartOfDay('2023-01-01') // 1672617600
 * getTimestampStartOfDay('2023-01-01T00:00:00.000Z') // 1672617600
 */
export const getTimestampStartOfNextDay = (date, timezoneName) => {
  if (timezoneName) {
    const dateInSelectedTimezone = moment.tz(date, timezoneName)
    return dateInSelectedTimezone.add(1, 'day').startOf('day').unix()
  }
  return moment(date).add(1, 'day').startOf('day').unix()
}

/**
 * Returns a timestamp corresponding to the end of day for the given date string
 * @param {string} date as string (YYYY-MM-DD) or in ISO format
 * @param {string} timezoneName as string (IANA timezone name) e.g. 'America/Los_Angeles'
 * @returns {number} The timestamp of the end of the day
 * If timezoneName is provided, the timestamp will be end of day for the provided timezoneName.
 * Otherwise, it will be end of day for the browser's timezone
 * @example
 * getTimestampEndOfDay('2023-01-01') // 1672617599
 * getTimestampEndOfDay('2023-01-01T00:00:00.000Z') // 1672617599
 */
export const getTimestampEndOfDay = (date, timezoneName) =>
  timezoneName
    ? moment.tz(date, timezoneName).endOf('day').unix()
    : moment(date).endOf('day').unix()

/**
 * Returns a timestamp corresponding to the start of the day N days previous to the given date string
 * @param {string} date as string (YYYY-MM-DD) or in ISO format
 * @param {number} numberOfDays number of days to subtract from the given date
 * @param {string} timezoneName as string (IANA timezone name) e.g. 'America/Los_Angeles'
 * @returns {number} The timestamp of the start of the day
 * @example
 * getTimestampStartOfNDaysAgo('2023-01-01', 90) // 1638278400
 */
export const getTimestampStartOfNDaysAgo = ({
  date,
  numberOfDays,
  timezoneName,
}) =>
  moment
    .tz(date, timezoneName)
    .subtract(numberOfDays, 'days')
    .startOf('day')
    .unix()

/**
 * Rounds down time to 30 minutes.
 * @param {number} timestamp unix timestamp
 * @param {number} interval Rounding interval, in minutes
 *
 * @returns {number} Unix timestamp rounded down to nearest interval
 */
export const floorTimestamp = (timestamp, interval = duration.HALF_HOUR) => {
  const intervalInMilliseconds =
    SECOND_IN_MILLISECONDS * MINUTE_IN_SECONDS * interval
  const timestampInMilliseconds = timestamp * SECOND_IN_MILLISECONDS
  const flooredTimeStamp =
    Math.floor(timestampInMilliseconds / intervalInMilliseconds) *
    intervalInMilliseconds
  return Math.floor(flooredTimeStamp / 1000)
}

/**
 * Converts and formats a Moment object to the specified timezone with the given format
 * @param {Moment} Moment object
 * @param {string} timezoneName timezone name (e.g. 'America/Los_Angeles')
 * @param {string} format format string (e.g. 'YYYY-MM-DD HH:mm:ss')
 * @returns {string} formatted time (e.g. '2023-01-01 12:00:00')
 */
export const convertMomentObjectToSpecifiedTimezoneWithFormatting = ({
  time,
  timezoneName,
  format,
}) => moment.tz(time, timezoneName).format(format)

/**
 * Converts time to unix timestamp taking into account the provided timezone name
 * @param {string} time time or date string (e.g. '2023-11-26T01:07:17-05:00', '2023-11-26 01:07:17' or '2023-11-26')
 * @param {string} timezoneName timezone name (e.g. 'America/New_York')
 * @returns {number} unix timestamp (e.g. 1700978837). If given only a date string, it will return start of day in the given timezone
 */
export const convertTimeToUnixTimestamp = ({ time, timezoneName }) =>
  moment.tz(time, timezoneName).unix()

/**
 * Converts unix timestamp to the specified timezone
 * @param {number} timestamp unix timestamp (e.g. 1606946887000)
 * @param {string} timezoneName timezone name (e.g. 'America/New_York')
 * @param {string} format format string (e.g. 'YYYY-MM-DD HH:mm:ss')
 * @returns {string} formatted time (e.g. '2020-12-02 17:08:07')
 */
export const convertUnixTimestampToSpecifiedTimezone = ({
  timestamp,
  timezoneName,
  format,
}) => moment(timestamp).tz(timezoneName).format(format)

/**
 * Converts a UTC time to the equivalent time in the timezone provided.
 * @param {string} utcTime the UTC time to convert, e.g. "2020-05-20 12:00:00" or "2022-06-09T18:51:29-06:00"
 * @param {string} timezone the timezone to convert to, e.g. "America/Los_Angeles"
 * @param {string} format the format to return the time in, e.g. "h:mm A"
 * @returns {string} The time in the provided timezone
 */
export const convertUTCStringToTimeInSpecifiedTimezone = (
  utcTime,
  timezone,
  format,
) => moment.utc(utcTime).tz(timezone).format(format)

/**
 * Converts a time to AM/PM format
 * @param {string} time the time to convert, e.g. "12:00" or "18:51"
 * @returns {string} The time in AM/PM format
 */
export const convertTimeToAMPMFormat = (time) => {
  const [hour, minute] = time.split(':').map(Number)
  if (hour === 0) {
    return `12:${minute < 10 ? `0${minute}` : minute}AM`
  } else if (hour < 12) {
    return `${hour}:${minute < 10 ? `0${minute}` : minute}AM`
  } else if (hour === 12) {
    return `${hour}:${minute < 10 ? `0${minute}` : minute}PM`
  } else {
    return `${hour - 12}:${minute < 10 ? `0${minute}` : minute}PM`
  }
}

/**
 * Converts an array of times to AM/PM format
 * @param {string[]} times the times to convert, e.g. ["12:00", "18:51"]
 * @returns {string[]} The times in AM/PM format
 */
export const convertTimesToAMPMFormat = (times) =>
  times.map((time) => convertTimeToAMPMFormat(time))

/**
 * Converts a string representing a date to the specified format
 * @param {string} dateString the date string to convert, e.g. "2020-05-09"
 * @param {string} format the format to convert to, e.g. "MMMM DD, YYYY"
 * @returns {string} The date in the specified format, e.g. "May 09, 2020"
 */
export const formatDateString = ({ dateString, format }) =>
  moment(dateString).format(format)
