import { formatBarChartData } from 'ui/clinicianScreens/Patient/Overview/helpers'
import { BarChart, Stack } from 'ui/baseComponents'
import { colors } from 'theme/colors'
import useTremorAndDyskinesiaAggregate from 'ui/hooks/summaryAggregates/useTremorAndDyskinesiaAggregate'
import { TEN_MINUTES_IN_SECONDS } from 'utilities/time'
import { getCombinedMaxValue } from 'ui/clinicianScreens/Patient/Report/helpers'
import DailyChartTitle from 'ui/clinicianScreens/Patient/Daily/components/DailyChartTitle'
import {
  SECTION_TITLES,
  mediumChartHeightWithAxis,
  mediumChartHeightWithoutAxis,
} from 'ui/clinicianScreens/Patient/Daily/consts'
import { useEffect } from 'react'

const DailyTremorDyskinesia = ({
  date,
  isTremorFilterActive,
  isDyskinesiaFilterActive,
  hasTremorData,
  setHasTremorData,
  hasDyskinesiaData,
  setHasDyskinesiaData,
}) => {
  const {
    data: { tremor: tremorData, dyskinesia: dyskinesiaData },
  } = useTremorAndDyskinesiaAggregate(date, date, TEN_MINUTES_IN_SECONDS)

  useEffect(() => {
    if (tremorData?.numberOfDaysWithData && !hasTremorData) {
      setHasTremorData(true)
    }
    if (dyskinesiaData?.numberOfDaysWithData && !hasDyskinesiaData) {
      setHasDyskinesiaData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tremorData, dyskinesiaData])

  const tremorLegendItem = {
    name: SECTION_TITLES.tremor,
    color: colors.TREMOR[600],
  }

  const dyskinesiaLegendItem = {
    name: SECTION_TITLES.dyskinesia,
    color: colors.DYSKINESIA[500],
  }

  const formattedTremorData = formatBarChartData(
    tremorData,
    SECTION_TITLES.tremor,
  )
  const formattedDyskinesiaData = formatBarChartData(
    dyskinesiaData,
    SECTION_TITLES.dyskinesia,
  )

  const yAxisMax = getCombinedMaxValue(tremorData, dyskinesiaData)

  return (
    <>
      {isTremorFilterActive && (
        <Stack spacing={0}>
          <DailyChartTitle variant="head18B">
            {SECTION_TITLES.tremor}
          </DailyChartTitle>
          <BarChart
            {...{
              title: SECTION_TITLES.tremor,
              data: formattedTremorData,
              legendItem: tremorLegendItem,
              height: mediumChartHeightWithoutAxis,
              xAxisInterval: 11,
              yAxisInterval: 1,
              yAxisMax,
              yAxisUnit: ' m',
              hideXAxis: true,
              hideVerticalGridLines: true,
              chartId: `daily-chart-tremor-${date}`,
            }}
          />
        </Stack>
      )}

      {isDyskinesiaFilterActive && (
        <Stack>
          <DailyChartTitle variant="head18B">
            {SECTION_TITLES.dyskinesia}
          </DailyChartTitle>
          <BarChart
            {...{
              title: SECTION_TITLES.dyskinesia,
              data: formattedDyskinesiaData,
              legendItem: dyskinesiaLegendItem,
              height: mediumChartHeightWithAxis,
              xAxisInterval: 11,
              yAxisInterval: 1,
              yAxisMax,
              yAxisUnit: ' m',
              hideXAxisTickLine: true,
              hideVerticalGridLines: true,
              chartId: `daily-chart-dyskinesia-${date}`,
            }}
          />
        </Stack>
      )}
    </>
  )
}

export default DailyTremorDyskinesia
