import { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import { DateRangeContext, DATE_RANGE } from 'ui/contexts/DateRangeContext'
import { createArrayOfDatesInDescendingOrder } from './helpers/helpers'
import DailyViewsHeader from '../Tabs/TabContentHeaders/Layouts/Tabs/DailyViewsHeader'
import { Text, Stack } from 'ui/baseComponents'
import { DATE_FORMAT_HUMAN_SHORT } from 'ui/consts'
import useBoundStore from 'domains/zustand/store'
import { colors } from 'theme/colors'
import { getEventName } from 'ui/clinicianScreens/Patient/helpers'
import {
  ALL_DAILY_EVENT_CATEGORIES,
  DAILY_EVENT_CATEGORIES,
} from 'ui/clinicianScreens/Patient/Daily/consts'
import DailyFilters from 'ui/clinicianScreens/Patient/Daily/components/DailyFilters'
import DailyTremorDyskinesia from 'ui/clinicianScreens/Patient/Daily/sections/DailyTremorDyskinesia'
import DailyMedication from 'ui/clinicianScreens/Patient/Daily/sections/DailyMedication'
import DailySymptoms from 'ui/clinicianScreens/Patient/Daily/sections/DailySymptoms'
import DailyNotes from 'ui/clinicianScreens/Patient/Daily/sections/DailyNotes'
import DailyCheckinSummary from 'ui/clinicianScreens/Patient/Daily/sections/DailyCheckinSummary'
import { getCombinedMedicationNameAndDosage } from 'ui/clinicianScreens/Patient/Daily/sections/helpers'
// import DailySleep from 'ui/clinicianScreens/Patient/Daily/sections/DailySleep'

/**
 * The daily view with comparative charts for a variety of logged items
 *
 * @returns {JSX.Element} Daily View parent component. All Daily View components are descendants of this component.
 */
const DailyNew = () => {
  const STYLES = {
    title: {
      backgroundColor: colors.COOL[100],
      padding: '0.5rem 1rem',
    },
    content: {
      padding: '1rem',
      flex: 1,
    },
    chartContainer: {
      position: 'relative',
    },
    chartBackground: {
      position: 'absolute',
      top: '2rem',
      bottom: '1.8rem',
      left: '74.3px',
      right: '11.7px',
      backgroundImage: `repeating-linear-gradient(to right, transparent, transparent 0px, ${colors.TREMOR[100]} 0px, ${colors.TREMOR[100]} 1px, white 1px, white calc(100% / 24))`,
      zIndex: 1,

      '&::after': {
        content: '""',
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        backgroundImage: `repeating-linear-gradient(to right, transparent, transparent 0px, ${colors.TREMOR[100]} 0px, ${colors.TREMOR[100]} 2px, transparent 2px, transparent calc(100% / 12))`,
        zIndex: 2,
      },
    },
    chartContent: {
      position: 'relative',
      zIndex: 3,
    },
  }
  const { dateRanges } = useContext(DateRangeContext)
  const eventList = useBoundStore((state) => state.eventList)
  const [dateArray, setDateArray] = useState([])

  const [hasTremorData, setHasTremorData] = useState(false)
  const [hasDyskinesiaData, setHasDyskinesiaData] = useState(false)
  const [symptomEvents, setSymptomEvents] = useState([])
  const [medicationEvents, setMedicationEvents] = useState([])
  const [checkinEvents, setCheckinEvents] = useState([])
  const [notesEvents, setNotesEvents] = useState([])

  const [medicationInitialFilters, setMedicationInitialFilters] = useState(
    new Set(),
  )
  const [symptomInitialFilters, setSymptomInitialFilters] = useState(new Set())

  const [medicationActiveFilters, setMedicationActiveFilters] = useState([])
  const [symptomActiveFilters, setSymptomActiveFilters] = useState([])

  const [isTremorFilterActive, setIsTremorFilterActive] = useState(true)
  const [isDyskinesiaFilterActive, setIsDyskinesiaFilterActive] = useState(true)
  const [isMedicationFilterActive, setIsMedicationFilterActive] = useState(true)
  const [isSymptomFilterActive, setIsSymptomFilterActive] = useState(true)
  const [isNotesFilterActive, setIsNotesFilterActive] = useState(true)
  const [isSleepFilterActive, setIsSleepFilterActive] = useState(true)
  const [isCheckinFilterActive, setIsCheckinFilterActive] = useState(true)

  useEffect(() => {
    setIsMedicationFilterActive(medicationActiveFilters.length > 0)
  }, [medicationActiveFilters])

  useEffect(() => {
    setIsSymptomFilterActive(symptomActiveFilters.length > 0)
  }, [symptomActiveFilters])

  useEffect(() => {
    setMedicationActiveFilters([...medicationInitialFilters])
  }, [medicationInitialFilters])

  useEffect(() => {
    setSymptomActiveFilters([...symptomInitialFilters])
  }, [symptomInitialFilters])

  const hasMedicationEvents = medicationEvents.length > 0
  const hasSymptomEvents = symptomEvents.length > 0
  const hasNotesEvents = notesEvents.length > 0
  const hasCheckinEvents = checkinEvents.length > 0

  useEffect(() => {
    setIsTremorFilterActive(hasTremorData)
    setIsDyskinesiaFilterActive(hasDyskinesiaData)
    setIsMedicationFilterActive(hasMedicationEvents)
    setIsSymptomFilterActive(hasSymptomEvents)
    setIsNotesFilterActive(hasNotesEvents)
    setIsCheckinFilterActive(hasCheckinEvents)
  }, [
    hasTremorData,
    hasDyskinesiaData,
    hasMedicationEvents,
    hasSymptomEvents,
    hasNotesEvents,
    hasCheckinEvents,
  ])

  const isEmpty =
    !hasTremorData &&
    !hasDyskinesiaData &&
    !hasMedicationEvents &&
    !hasSymptomEvents &&
    !hasNotesEvents &&
    !hasCheckinEvents

  const { start: startTime, end: endTime } =
    dateRanges[DATE_RANGE.default.key] || {}

  useEffect(() => {
    setDateArray(createArrayOfDatesInDescendingOrder(endTime, startTime))
  }, [endTime, startTime])

  useEffect(() => {
    if (eventList) {
      eventList?.forEach((event) => {
        if (
          ALL_DAILY_EVENT_CATEGORIES.includes(event.classification.category) &&
          moment
            .unix(event.duration.startTime)
            .isSameOrAfter(moment(startTime)) &&
          moment.unix(event.duration.endTime).isSameOrBefore(moment(endTime))
        ) {
          const eventName = getEventName(event)
          switch (event.classification.category) {
            case DAILY_EVENT_CATEGORIES.sideEffect:
            case DAILY_EVENT_CATEGORIES.symptom: {
              const { payload } = event
              const eventPayload = JSON.parse(payload)
              const fromDailyCheckin = eventPayload?.from_daily_checkin
              if (fromDailyCheckin) {
                if (!checkinEvents.find((checkin) => checkin.id === event.id)) {
                  setCheckinEvents((prev) => [...prev, event])
                }
              } else {
                setSymptomEvents((prev) => [...prev, event])
                if (!symptomInitialFilters.has(eventName)) {
                  setSymptomInitialFilters(
                    (prev) => new Set([...prev, eventName]),
                  )
                }
                // if endTime is the day after the startDate, add it to the next day
                const eventStartDate = moment
                  .unix(event.duration.startTime)
                  .format('DD')
                const eventEndDate = moment
                  .unix(event.duration.endTime)
                  .format('DD')
                if (eventStartDate !== eventEndDate) {
                  const newStartTime = moment
                    .unix(event.duration.endTime)
                    .startOf('day')
                    .unix()
                  const nextDayEvent = {
                    ...event,
                    duration: { ...event.duration, startTime: newStartTime },
                  }
                  setSymptomEvents((prev) => [...prev, nextDayEvent])
                }
              }
              break
            }
            case DAILY_EVENT_CATEGORIES.medication: {
              setMedicationEvents((prev) => [...prev, event])
              const combinedMedicationDosage =
                getCombinedMedicationNameAndDosage(event)
              if (!medicationInitialFilters.has(combinedMedicationDosage)) {
                setMedicationInitialFilters(
                  (prev) => new Set([...prev, combinedMedicationDosage]),
                )
              }
              break
            }
            case DAILY_EVENT_CATEGORIES.note:
              if (!notesEvents.find((note) => note.id === event.id)) {
                setNotesEvents((prev) => [...prev, event])
              }
              break
            default:
              break
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventList])

  const filterProps = {
    hasTremorData,
    hasDyskinesiaData,
    hasMedicationEvents,
    hasSymptomEvents,
    hasNotesEvents,
    hasCheckinEvents,
    isTremorFilterActive,
    setIsTremorFilterActive,
    isDyskinesiaFilterActive,
    setIsDyskinesiaFilterActive,
    isMedicationFilterActive,
    setIsMedicationFilterActive,
    medicationInitialFilters,
    medicationActiveFilters,
    setMedicationActiveFilters,
    isSymptomFilterActive,
    setIsSymptomFilterActive,
    symptomInitialFilters,
    symptomActiveFilters,
    setSymptomActiveFilters,
    isNotesFilterActive,
    setIsNotesFilterActive,
    isSleepFilterActive,
    setIsSleepFilterActive,
    isCheckinFilterActive,
    setIsCheckinFilterActive,
  }

  return (
    <>
      <DailyViewsHeader />
      <Stack direction="row" spacing={0}>
        <DailyFilters {...filterProps} />
        <Stack sx={STYLES.content}>
          {dateArray.map((date) => (
            <>
              <Text
                variant="head20B"
                component="h1"
                sx={STYLES.title}
                key={`${date}-title`}
              >
                {moment(date).format(DATE_FORMAT_HUMAN_SHORT)}
              </Text>
              {isEmpty ? (
                <Stack
                  key={`${date}-empty-content`}
                  spacing={2}
                  sx={STYLES.content}
                >
                  <Text variant="body16">No data available for this date</Text>
                </Stack>
              ) : (
                <Stack key={`${date}-content`} spacing={2} sx={STYLES.content}>
                  <Stack sx={STYLES.chartContainer}>
                    <Stack sx={STYLES.chartBackground} />
                    <Stack sx={STYLES.chartContent}>
                      <DailyTremorDyskinesia
                        {...{
                          date,
                          isTremorFilterActive,
                          isDyskinesiaFilterActive,
                          hasTremorData,
                          setHasTremorData,
                          hasDyskinesiaData,
                          setHasDyskinesiaData,
                        }}
                      />
                      {isMedicationFilterActive && (
                        <DailyMedication
                          {...{
                            date,
                            data: medicationEvents,
                            activeFilters: medicationActiveFilters,
                          }}
                        />
                      )}
                      {isSymptomFilterActive && (
                        <DailySymptoms
                          {...{
                            date,
                            data: symptomEvents,
                            activeFilters: symptomActiveFilters,
                          }}
                        />
                      )}
                      {/* {isSleepFilterActive && (
                    <DailySleep {...{ date, data: sleepEvents }} />
                  )} */}
                    </Stack>
                  </Stack>
                  {isCheckinFilterActive && (
                    <DailyCheckinSummary {...{ date, data: checkinEvents }} />
                  )}
                  {isNotesFilterActive && (
                    <DailyNotes {...{ date, data: notesEvents }} />
                  )}
                </Stack>
              )}
            </>
          ))}
        </Stack>
      </Stack>
    </>
  )
}

export default DailyNew
