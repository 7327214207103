import { memo } from 'react'
import { Stack, Text } from 'ui/baseComponents'
import { getDataByDate } from 'ui/clinicianScreens/Patient/Daily/sections/helpers'
import DailyChartTitle from 'ui/clinicianScreens/Patient/Daily/components/DailyChartTitle'
import { SECTION_TITLES } from 'ui/clinicianScreens/Patient/Daily/consts'
import { getEventName } from 'ui/clinicianScreens/Patient/helpers'

const DailyCheckinSummary = ({ date, data = [] }) => {
  const dataByDate = getDataByDate(data, date)
  const checkinNames = dataByDate.map((checkin) => getEventName(checkin))

  return dataByDate.length > 0 ? (
    <Stack>
      <DailyChartTitle variant="head18B" component="h2">
        {SECTION_TITLES.symptoms} ({SECTION_TITLES.checkin})
      </DailyChartTitle>
      <Text variant="body16" component="p">
        {checkinNames.join(', ')}
      </Text>
    </Stack>
  ) : (
    <div />
  )
}

export default memo(DailyCheckinSummary)
